import { Box, Flex } from "@chakra-ui/react";
import axios from "axios";
import React, { Suspense, useLayoutEffect } from "react";
import ReactGA from "react-ga4";

import { Routes, Route } from "react-router-dom";
import "./App.css";
import DashboardNavbar from "./components/dashboard/Navbar";
import Requests from "./components/dashboard/requests";
import Loader from "./components/Loader";
import Navbar from "./components/nav-bar";
import useAuth from "./hooks/useAuth";
import Profile from "./pages/profile";
import TrackPageView from "./components/google-analytics";

const CreateAccount = React.lazy(() => import("./pages/create-account"));
const SignUp = React.lazy(() => import("./pages/create-account/signup"));
const BankDetails = React.lazy(
  () => import("./pages/create-account/documentsUpload"),
);
const LandingPage = React.lazy(() => import("./pages/landing"));

const LoginPage = React.lazy(() => import("./pages/login"));
const ForgetPasswordPage = React.lazy(() => import("./pages/forgot-password"));
const ResetPasswordPage = React.lazy(() => import("./pages/reset-password"));
const Dashboard = React.lazy(() => import("./pages/dashboard"));
const Network = React.lazy(
  () => import("./components/dashboard/network/Network"),
);
const Account = React.lazy(() => import("./components/dashboard/dashboard"));
const Borrow = React.lazy(() => import("./components/dashboard/borrow/index"));

const NetworkInvite = React.lazy(
  () => import("./pages/network-invite/NetworkInvite"),
);

const ActiveInvites = React.lazy(() => import("./pages/active-connections"));
const LoanRepayment = React.lazy(() => import("./pages/loan-repayment"));

const AdminDashboard = React.lazy(() => import("./pages/admin-dashboard"));
const AdminAccount = React.lazy(
  () => import("./components/admin-dashboard/main-dashboard"),
);
const AdminProfile = React.lazy(
  () => import("./components/admin-dashboard/admin-profile"),
);
const AgentManagement = React.lazy(
  () => import("./components/admin-dashboard/agent-management"),
);

const UserManagement = React.lazy(
  () => import("./components/admin-dashboard/user-management"),
);

const AgentDashboard = React.lazy(() => import("./pages/agent-dashboard"));
const AgentAccount = React.lazy(
  () => import("./components/agent-dashboard/main-dashboard"),
);
const AgentTransactions = React.lazy(
  () => import("./components/agent-dashboard/transactions"),
);
const AgentProfile = React.lazy(
  () => import("./components/agent-dashboard/agent-profile"),
);

const LoanAcceptance = React.lazy(() => import("./pages/loan-acceptance"));

const UserLoans = React.lazy(
  () => import("./components/admin-dashboard/user-loans"),
);

const AdminTransactions = React.lazy(
  () => import("./components/admin-dashboard/all-transactions"),
);

const TermsAndConditions = React.lazy(
  () => import("./pages/privacy-policies/TermsAndConditions"),
);

const PrivacyPolicy = React.lazy(
  () => import("./pages/privacy-policies/PrivacyPolicy"),
);

function App() {
  const { isLoggedIn, loggedInUser, signOut } = useAuth();

  useLayoutEffect(() => {
    (async () => {
      axios.defaults.baseURL =
        "https://thebe-develop.azurewebsites.net/api/v1/";
    })();
  }, []);

  ReactGA.initialize("G-PXE8LJFCTY");

  ReactGA.send("pageview");

  return (
    <Box className="App" textColor="black" minH="100vh">
      <Box>
        {isLoggedIn ? (
          <DashboardNavbar
            username={loggedInUser?.firstname}
            signOut={signOut}
          />
        ) : (
          <Navbar />
        )}

        <Suspense
          fallback={
            <Flex
              direction="column"
              h="100vh"
              w="full"
              justifyContent="center"
              alignItems="center"
            >
              <Loader />
            </Flex>
          }
        >
          <TrackPageView />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/forgot-password" element={<ForgetPasswordPage />} />
            <Route
              path="/reset-password/:resetKey"
              element={<ResetPasswordPage />}
            />
            <Route path="create-profile" element={<CreateAccount />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="bank-details" element={<BankDetails />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="loan-acceptance" element={<LoanAcceptance />} />

            {/* privacy policies */}
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />

            {/* user dashboard */}
            <Route path="dashboard" element={<Dashboard />}>
              <Route index element={<Account />} />
              <Route path="profile" element={<Profile />} />
              <Route path="account" element={<Account />} />
              <Route path="network" element={<Network />} />
              <Route path="borrow" element={<Borrow />} />
              <Route path="requests" element={<Requests />} />
              <Route path="network/invite" element={<NetworkInvite />} />
              <Route
                path="account/repay-loan/:loanId"
                element={<LoanRepayment />}
              />
              {/* Temporary fix for loan repay */}
              <Route path="repay-loan/:loanId" element={<LoanRepayment />} />
              <Route
                path="network/active-invites"
                element={<ActiveInvites />}
              />
            </Route>

            {/* Admin dashboard */}
            <Route path="admin-dashboard" element={<AdminDashboard />}>
              <Route index element={<AdminAccount />} />
              <Route path="admin-account" element={<AdminAccount />} />
              <Route path="admin-profile" element={<AdminProfile />} />
              <Route path="agent-management" element={<AgentManagement />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route path="transactions" element={<AdminTransactions />} />
              <Route path="user-loans" element={<UserLoans />} />
            </Route>

            {/* Agent dashboard */}
            <Route path="agent-dashboard" element={<AgentDashboard />}>
              <Route index element={<AgentAccount />} />
              <Route path="agent-account" element={<AgentAccount />} />
              <Route path="transactions" element={<AgentTransactions />} />
              <Route path="agent-profile" element={<AgentProfile />} />
            </Route>
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
}

export default App;
